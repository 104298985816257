import axios from "@/config/http.js";

export const API = {
  // 分页查询阅览-询盘-成交选品
  async pageIndex(params) {
    return await axios.get("/analysis/trader/action", { params });
  },
  // 商户下拉
  async merchantDrop(params) {
    return await axios.get("/analysis/trader/dropdown", { params });
  },
};
