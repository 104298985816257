<template>
  <div class="merchant-analysis-container container-index">
    <a-row class="mb2">
      <a-form-model
        layout="inline"
        style="display: flex; justify-content: space-between"
      >
        <a-form-model-item label="商户">
          <a-select
            show-search
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => handleDislocation(triggerNode)"
            :default-active-first-option="false"
            :style="{ width: '28.5rem' }"
            :not-found-content="null"
            :filterOption="filterOpt"
            v-model="queryFrom.tradeId"
          >
            <a-select-option
              v-for="(item, i) in merchantList"
              :value="item.tradeId"
              :key="i"
            >
              {{ item.tradeName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="操作时间">
          <a-range-picker
            format="YYYY-MM-DD"
            @change="handleDate"
            style="width: 40rem"
            v-model="DateTime"
          />
        </a-form-model-item>

        <div class="right" style="line-height: 4rem">
          <a-button type="reload" @click="handleReset">重置</a-button>
          <a-button type="primary" @click="queryBtn">查询</a-button>
        </div>
      </a-form-model>
    </a-row>
    <a-tabs default-active-key="1" @change="handleTabChange">
      <a-tab-pane key="1" tab="阅览选品">
        <a-table
          :columns="columns"
          :data-source="data"
          :key="data.key"
          :loading="loading"
          :scroll="{ y: 450 }"
          :pagination="pagination"
        >
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="询盘选品">
        <a-table
          :columns="columns"
          :data-source="data"
          :key="data.key"
          :loading="loading"
          :scroll="{ y: 450 }"
          :pagination="pagination"
        >
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="3" tab="成交选品">
        <a-table
          :columns="columns"
          :data-source="data"
          :key="data.key"
          :loading="loading"
          :scroll="{ y: 450 }"
          :pagination="pagination"
        >
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
const columns = [
  {
    title: "商户名称",
    ellipsis: true,
    dataIndex: "tradeName",
  },
  {
    title: "商品名称",
    ellipsis: true,
    dataIndex: "goodsNameCn",
  },
  {
    title: "商品分类",
    ellipsis: true,
    dataIndex: "goodsCategoryName",
  },
  {
    title: "操作时间",
    ellipsis: true,
    dataIndex: "applyTime",
  },
  {
    title: "基础价",
    ellipsis: true,
    dataIndex: "onPrice",
  },
  {
    title: "定制起订量",
    ellipsis: true,
    dataIndex: "minOrder",
  },
  {
    title: "可售区域",
    ellipsis: true,
    dataIndex: "availableArea",
  },
  {
    title: "工厂名称",
    ellipsis: true,
    dataIndex: "factoryName",
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { filterOpt } from "@/utils/utils.js";
import { API } from "@/apix/merchantAnasysis";
import moment from "moment";

export default {
  mixins: [pagination, mixins],
  components: {},
  data() {
    return {
      columns,
      data: [],
      loading: false,
      merchantList: [], // 商户数据
      queryFrom: {
        tradeId: undefined, // 商户Id
        action: "1", // 查询类型
        startTime: null,
        endTime: null,
      },
      DateTime: null,
    };
  },
  methods: {
    filterOpt,
    moment,
    handleTabChange(val) {
      this.queryFrom.action = val;
      this.pagination.current = 1;
      this.getSourceData();
    },
    getSourceData() {
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
      };
      this.loading = true;
      API.pageIndex(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },
    // 处理日期
    handleDate(date) {
      if (date.length === 0) {
        this.queryFrom.startTime = null;
        this.queryFrom.endTime = null;
      } else {
        this.queryFrom.startTime = moment(date[0]).format("YYYY-MM-DD");
        this.queryFrom.endTime = moment(date[1]).format("YYYY-MM-DD");
      }
    },
    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      this.queryFrom.tradeId = null;
      this.queryFrom.startTime = null;
      this.queryFrom.endTime = null;
      this.DateTime = null;
      this.getSourceData();
    },
    // 获取商户数据
    getMerchantData() {
      API.merchantDrop().then((res) => {
        this.merchantList = res.data;
      });
    },
  },
  created() {
    this.getSourceData();
    this.getMerchantData();
  },
};
</script>

<style lang="scss" scoped></style>
